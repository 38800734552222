import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { AppBar, Card, RaisedButton, TextField } from "material-ui";
import { primaryColor, secondaryColor } from "../theme/colors";
import { CardContent } from "@material-ui/core";
import { vazirMedium, vazir } from "../theme/fonts";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useAlert } from "react-alert";

const SenderAndRecieverInfo = props => {
  const { width } = useWindowDimensions();

  const alert = useAlert();

  const fixNumbers = str => {
    var
      persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
      arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

    if(typeof str === 'string')
    {
      for(var i=0; i<10; i++)
      {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  }

  const continues = e => {
    const reg = /(\+989|9|09)\d{9}/;

    const sPhone = fixNumbers(values.sPhone);
    const rPhone = fixNumbers(values.rPhone);

    e.preventDefault();
    if (values.sName === "") {
      alert.info("لطفاً نام و نام خانوادگی فرستنده را وارد کنید", {
        timeout: 3000
      });
    } else if (sPhone === "") {
      alert.info("لطفاً شماره فرستنده را وارد کنید", {
        timeout: 3000
      });
    } else if (!reg.test(sPhone) || sPhone.length > 13) {
      alert.info("لطفاً شماره فرستنده را به صورت صحیح کنید", {
        timeout: 3000
      });
    } else if (
      (values.sendMethod === "DTD" || values.sendMethod === "DTA") &&
      values.sAddress === ""
    ) {
      alert.info("لطفاً آدرس فرستنده را وارد کنید", {
        timeout: 3000
      });
    } else if (values.rName === "") {
      alert.info("لطفاً نام و نام خانوادگی گیرنده را وارد کنید", {
        timeout: 3000
      });
    } else if (rPhone === "") {
      alert.info("لطفاً شماره گیرنده را وارد کنید", {
        timeout: 3000
      });
    } else if (!reg.test(rPhone) || rPhone.length > 13) {
      alert.info("لطفاً شماره گیرنده را به صورت صحیح کنید", {
        timeout: 3000
      });
    } else if (
      (values.sendMethod === "DTD" || values.sendMethod === "ATD") &&
      values.rAddress === ""
    ) {
      alert.info("لطفاً آدرس گیرنده را وارد کنید", {
        timeout: 3000
      });
    } else {
      props.nextStep();
    }
  };

  const back = e => {
    e.preventDefault();
    props.prevStep();
  };

  const { values, handleChange } = props;

  return (
    <MuiThemeProvider>
      <>
        <div dir="rtl">
          <AppBar
            title="مشخصات فرستنده و گیرنده"
            titleStyle={{ fontFamily: vazir }}
            showMenuIconButton={false}
            style={{ backgroundColor: primaryColor }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30
            }}
          >
            <Card
              style={{
                justifyContent: "center"
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <CardContent>
                  <p
                    style={{
                      fontFamily: vazirMedium,
                      alignSelf: "flex-start",
                      display: "flex",
                      fontSize: 20
                    }}
                  >
                    مشخصات فرستنده
                  </p>
                </CardContent>
                <CardContent>
                  <TextField
                    floatingLabelText="نام و نام خانوادگی فرستنده"
                    floatingLabelStyle={{
                      fontFamily: vazir,
                      textAlign: "right"
                    }}
                    style={{
                      fontFamily: vazir,
                      marginLeft: width > 605 ? 30 : 0
                    }}
                    onChange={handleChange("sName")}
                    value={values.sName}
                  />
                  <TextField
                    floatingLabelText="شماره تماس فرستنده"
                    floatingLabelStyle={{
                      fontFamily: vazir,
                      textAlign: "right",
                      position: "absolute",
                      right: 0
                    }}
                    style={{ fontFamily: vazir }}
                    inputStyle={{ fontFamily: vazir, textAlign: "left" }}
                    onChange={handleChange("sPhone")}
                    value={values.sPhone}
                  />
                </CardContent>
                {values.sendMethod === "ATA" ||
                values.sendMethod === "ATD" ? null : (
                  <CardContent style={{ justifyContent: "stretch" }}>
                    <TextField
                      floatingLabelText="آدرس فرستنده"
                      floatingLabelStyle={{
                        fontFamily: vazir,
                        textAlign: "right",
                        position: "absolute",
                        right: 0
                      }}
                      multiLine
                      rowsMax={4}
                      id="standard-multiline-flexible"
                      style={{
                        fontFamily: vazir,
                        margin: "dense",
                        width: width >= 605 ? "100%" : 260
                      }}
                      onChange={handleChange("sAddress")}
                      value={values.sAddress}
                    />
                  </CardContent>
                )}
              </CardContent>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <CardContent>
                  <p
                    style={{
                      fontFamily: vazirMedium,
                      alignSelf: "flex-start",
                      display: "flex",
                      fontSize: 20
                    }}
                  >
                    مشخصات گیرنده
                  </p>
                </CardContent>
                <CardContent>
                  <TextField
                    floatingLabelText="نام و نام خانوادگی گیرنده"
                    floatingLabelStyle={{
                      fontFamily: vazir,
                      textAlign: "right"
                    }}
                    style={{
                      fontFamily: vazir,
                      marginLeft: width > 605 ? 30 : 0
                    }}
                    onChange={handleChange("rName")}
                    value={values.rName}
                  />
                  <TextField
                    floatingLabelText="شماره تماس گیرنده"
                    floatingLabelStyle={{
                      fontFamily: vazir,
                      textAlign: "right",
                      position: "absolute",
                      right: 0
                    }}
                    style={{ fontFamily: vazir }}
                    inputStyle={{ fontFamily: vazir, textAlign: "left" }}
                    onChange={handleChange("rPhone")}
                    value={values.rPhone}
                  />
                </CardContent>
                {values.sendMethod === "DTA" ||
                values.sendMethod === "ATA" ? null : (
                  <CardContent style={{ justifyContent: "stretch" }}>
                    <TextField
                      floatingLabelText="آدرس گیرنده"
                      floatingLabelStyle={{
                        fontFamily: vazir,
                        textAlign: "right",
                        position: "absolute",
                        right: 0
                      }}
                      multiLine
                      rowsMax={4}
                      id="standard-multiline-flexible"
                      style={{
                        fontFamily: vazir,
                        margin: "dense",
                        width: width >= 605 ? "100%" : 260
                      }}
                      onChange={handleChange("rAddress")}
                      value={values.rAddress}
                    />
                  </CardContent>
                )}
              </CardContent>
              <CardContent style={{ alignSelf: "center", marginTop: 15 }}>
                <RaisedButton
                  label="ادامه"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={continues}
                  style={{ borderRadius: 35, zIndex: -200 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={secondaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
                <RaisedButton
                  label="برگشت"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={back}
                  style={{ borderRadius: 35, marginRight: 30, zIndex: -1 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={primaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    </MuiThemeProvider>
  );
};

export default SenderAndRecieverInfo;
