import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { RaisedButton } from "material-ui";
import { FormControl } from "@material-ui/core";
import Select from "react-select";
import { secondaryColor, primaryColor } from "../theme/colors";
import { vazirMedium, vazir } from "../theme/fonts";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

const styles = {
  control: (base) => ({
    ...base,
    fontFamily: vazir,
  }),
  menu: (base) => ({
    ...base,
    fontFamily: vazir,
  }),
};

const cities = [
  { value: "tehran", label: "تهران" },
  { value: "kerman", label: "کرمان" },
  { value: "mashad", label: "مشهد" },
  { value: "bandarabas", label: "بندرعباس" },
  { value: "isfahan", label: "اصفهان" },
  { value: "kish", label: "کیش" },
  { value: "qeshm", label: "قشم" },
  { value: "zahedan", label: "زاهدان" },
  { value: "shiraz", label: "شیراز" },
  { value: "bushehr", label: "بوشهر" },
  { value: "ahwaz", label: "اهواز" },
  { value: "azabayjanGH", label: "آذربایجان غربی" },
  { value: "azarbayjanSH", label: "آذربایجان شرقی" },
  { value: "ardabil", label: "اردبیل" },
  { value: "birjand", label: "بیرجند" },
  { value: "bojnord", label: "بجنورد" },
  { value: "ilam", label: "ایلام" },
  { value: "yasuj", label: "یاسوج" },
  { value: "shahrkord", label: "شهرکرد" },
  { value: "khoramAbad", label: "خرم آباد" },
  { value: "hamedan", label: "همدان" },
  { value: "arak", label: "اراک" },
  { value: "kermanShah", label: "کرمانشاه" },
  { value: "sanandaj", label: "سنندج" },
  { value: "semnan", label: "سمنان" },
  { value: "yazd", label: "یزد" },
  { value: "lahijan", label: "لاهیجان" },
  { value: "sari", label: "ساری" },
  { value: "gorgan", label: "گرگان" },
  { value: "qom", label: "قم" },
];

const OriginAndDestinationInfo = (props) => {
  const classes = useStyles();

  const alert = useAlert();

  const { values, handleChange } = props;

  const defaultValue = [
    { value: "origin", label: "مبداء" },
    { value: "destination", label: "مقصد" },
  ];

  const continues = (e) => {
    e.preventDefault();
    if (values.origin === "") {
      alert.info("لطفاً مبداء را انتخاب کنید", {
        timeout: 3000,
      });
    } else if (values.destination === "") {
      alert.info("لطفاً مقصد را انتخاب کنید", {
        timeout: 3000,
      });
    } else if (values.origin.label === values.destination.label) {
      alert.info("مبداء و مقصد نمیتوانند یکسان باشند", {
        timeout: 3000,
      });
    } else {
      props.nextStep();
    }
  };

  const back = (e) => {
    if (e) {
      e.preventDefault();
      props.prevStep();
    } else {
      props.prevStep();
    }
  };

  // const [isLoading, setIsLoading] = useState(true);
  // const [cities, setCities] = useState([]);

  // const getCities = () => {
  //   const cs = [];

  //   const FETCH_TIMEOUT = 10000;
  //   let didTimeout = false;

  //   new Promise((resolve, reject) => {
  //     const timeout = setTimeout(() => {
  //       didTimeout = true;
  //       reject(new Error("Request time out"));
  //     }, FETCH_TIMEOUT);

  //     axios
  //       .get(apiAddress + "/getCities")
  //       .then(response => {
  //         clearTimeout(timeout);
  //         if (!didTimeout) {
  //           resolve(response);
  //         }
  //       })
  //       .catch(err => {
  //         console.error("Error : " + err);
  //         if (didTimeout) return;
  //         reject(err);
  //       });
  //   })
  //     .then(response => {
  //       response.data.forEach(e => {
  //         const c = {
  //           value: e.CityCode,
  //           label: e.CityName
  //         };

  //         cs.push(c);
  //       });
  //       setIsLoading(false);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       alert.error("خطایی رخ داده لطفاً دوباره تلاش کنید", {
  //         onClose: back
  //       });
  //     });

  //   setCities(cs);
  // };

  // useEffect(() => {
  //   getCities();
  // }, []);

  return (
    <MuiThemeProvider>
      <>
        <div dir="rtl">
          <AppBar
            title="انتخاب مبداء و مقصد"
            titleStyle={{ fontFamily: vazir }}
            showMenuIconButton={false}
            style={{ backgroundColor: primaryColor }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30,
            }}
          >
            <Card
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingLeft: 10,
                flexDirection: "column",
                overflow: "visible",
              }}
            >
              <CardContent style={{ zIndex: 100 }}>
                <FormControl
                  className={classes.formControl}
                  style={{ zIndex: 101 }}
                >
                  <p style={{ alignSelf: "flex-start", fontFamily: vazir }}>
                    لطفاً مبداء را انتخاب کنید :{" "}
                  </p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={values.origin ? values.origin : defaultValue[0]}
                    // isLoading={isLoading}
                    isRtl={true}
                    isSearchable={true}
                    name="origin"
                    options={cities}
                    onChange={handleChange("origin", true)}
                    styles={styles}
                    loadingMessage={() => {
                      return "در حال بارگزاری ...";
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <p style={{ alignSelf: "flex-start", fontFamily: vazir }}>
                    لطفاً مقصد را انتخاب کنید :{" "}
                  </p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={
                      values.destination ? values.destination : defaultValue[1]
                    }
                    // isLoading={isLoading}
                    isRtl={true}
                    isSearchable={true}
                    name="origin"
                    options={cities}
                    onChange={handleChange("destination", true)}
                    styles={styles}
                    loadingMessage={() => {
                      return "در حال بارگزاری ...";
                    }}
                  />
                </FormControl>
              </CardContent>
              <CardContent style={{ alignSelf: "center" }}>
                <RaisedButton
                  label="ادامه"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={continues}
                  style={{ borderRadius: 35, zIndex: -200 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={secondaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
                <RaisedButton
                  label="برگشت"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={back}
                  style={{ borderRadius: 35, marginRight: 30, zIndex: -1 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={primaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    </MuiThemeProvider>
  );
};

export default OriginAndDestinationInfo;
