import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { RaisedButton } from "material-ui";
import { secondaryColor, primaryColor } from "../theme/colors";
import { vazirLight, vazir, vazirMedium } from "../theme/fonts";

const useStyles = makeStyles({
  label: {
    fontFamily: vazirLight,
    color: "black",
    textAlign: "right",
    lineHeight: 1.75
  },
  radio: {
    "&$checked": {
      color: primaryColor
    }
  },
  checked: {}
});

const SendingMethods = props => {
  const classes = useStyles();

  const continues = e => {
    e.preventDefault();
    props.nextStep();
  };

  const { values, handleChange } = props;

  return (
    <MuiThemeProvider>
      <div dir="rtl">
        <AppBar
          title="انتخاب نحوه ارسال بار"
          titleStyle={{ fontFamily: vazir }}
          style={{ backgroundColor: primaryColor }}
          showMenuIconButton={false}
        />
        <div
          style={{
            display: "flex",
            marginTop: 30,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 30
          }}
        >
          <Card
            style={{
              display: "flex",
              alignItems: "flex-start",
              paddingLeft: 10,
              flexDirection: "column",
              padding: 30
            }}
          >
            <CardContent>
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    fontFamily: vazir,
                    margintop: 10,
                    marginBottom: 15,
                    color: "black",
                    textAlign: "right",
                    marginRight: 10,
                    fontSize: 24
                  }}
                >
                  نحوه دریافت و ارسال بار
                </FormLabel>
                <br />
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={values.sendMethod}
                  onChange={handleChange("sendMethod")}
                >
                  <FormControlLabel
                    value="DTD"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="دریافت از درب منزل فرستنده و تحویل به درب منزل گیرنده"
                    classes={{
                      label: classes.label
                    }}
                  />
                  <br />
                  <FormControlLabel
                    value="DTA"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="دریافت از درب منزل فرستنده و تحویل در فرودگاه مقصد"
                    classes={{
                      label: classes.label
                    }}
                  />
                  <br />
                  <FormControlLabel
                    value="ATD"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="دریافت در فرودگاه مبداء و تحویل به درب منزل گیرنده"
                    classes={{
                      label: classes.label
                    }}
                  />
                  <br />
                  <FormControlLabel
                    value="ATA"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="دریافت در فرودگاه مبداء و تحویل در فرودگاه مقصد"
                    classes={{
                      label: classes.label
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
            <CardContent style={{ alignSelf: "center" }}>
              <RaisedButton
                label="ادامه"
                labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                onClick={continues}
                style={{ borderRadius: 35 }}
                overlayStyle={{ borderRadius: 35 }}
                backgroundColor={secondaryColor}
                buttonStyle={{ borderRadius: 35 }}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default SendingMethods;
