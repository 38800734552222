import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
// import AlertTemplate from "react-alert-template-basic";
import AlertTemplate from "./components/util/AlertTemplate";

import "./fonts/Vazir-FD.ttf";
import "./fonts/Vazir-Light-FD.ttf";
import "./fonts/Vazir-Black-FD.ttf";
import "./fonts/Vazir-Bold-FD.ttf";
import "./fonts/Vazir-Medium-FD.ttf";
import "./fonts/Vazir-Thin-FD.ttf";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  type: "error"
};

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
