import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { AppBar, Card, RaisedButton, TextField } from "material-ui";
import { vazir, vazirMedium } from "../theme/fonts";
import { primaryColor, secondaryColor } from "../theme/colors";
import { CardContent, makeStyles, FormControl } from "@material-ui/core";
import Select from "react-select";
import { useAlert } from "react-alert";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  }
}));

const styles = {
  control: base => ({
    ...base,
    fontFamily: vazir
  }),
  menu: base => ({
    ...base,
    fontFamily: vazir
  })
};

const contents = [
  {value:"yadaki", label: "یدکی"},
  {value:"oragh", label: "اوراق"},
  {value:"electronici", label: "الکترونیکی"},
  {value:"pezashki", label: "پزشکی"},
  {value:"ghazayi", label: "غذایی"},
  {value:"other", label: "سایر"},
]

const CargoInfo = props => {
  const classes = useStyles();

  const alert = useAlert();

  const { values, handleChange } = props;

  const fixNumbers = str => {
    var
      persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
      arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

    if(typeof str === 'string')
    {
      for(var i=0; i<10; i++)
      {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  }

  const continues = e => {
    e.preventDefault();

    console.log(values.num);
    
    const num = fixNumbers(values.num)
    const weigth= fixNumbers(values.weigth);

    if (num === "") {
      alert.info("لطفاً تعداد مرسولات را وارد کنید", {
        timeout: 3000
      });
    } else if (weigth === "") {
      alert.info("لطفاً وزن مرسولات را وارد کنید", {
        timeout: 3000
      });
    } else if (values.content === "") {
      alert.info("لطفاً محتویات مرسولات را انتخاب کنید", {
        timeout: 3000
      });
    } else if (values.paymentMethod === "") {
      alert.info("لطفاً نحوه پرداخت را انتخاب کنید", {
        timeout: 3000
      });
    } else {
      props.nextStep();
    }
  };

  const back = e => {
    if (e) {
      e.preventDefault();
      props.prevStep();
    } else {
      props.prevStep();
    }
  };

  //should get from api
  const defaultValues = [
    { value: "mohtaviat", label: "محتویات" },
    { value: "default", label: "نحوه پرداخت" }
  ];

  const paymentMethods = [
    { value: "naghdi", label: "نقدی" },
    { value: "paskeraye", label: "پسکرایه" },
    { value: "etebari", label: "اعتباری" }
  ];

  // const [isLoading, setIsLoading] = useState(true);
  // const [contents, setContents] = useState([]);

  // const getContetns = () => {
  //   const cs = [];

  //   const FETCH_TIMEOUT = 10000;
  //   let didTimeout = false;

  //   new Promise((resolve, reject) => {
  //     const timeout = setTimeout(() => {
  //       didTimeout = true;
  //       reject(new Error("Request time out"));
  //     }, FETCH_TIMEOUT);

  //     axios
  //       .get(apiAddress + "/getContents")
  //       .then(response => {
  //         clearTimeout(timeout);
  //         if (!didTimeout) {
  //           resolve(response);
  //         }
  //       })
  //       .catch(err => {
  //         console.error("Error : " + err);
  //         if (didTimeout) return;
  //         reject(err);
  //       });
  //   })
  //     .then(response => {
  //       response.data.forEach(element => {
  //         const c = {
  //           value: element.ContentCode,
  //           label: element.ContentName
  //         };

  //         cs.push(c);
  //       });
  //       setIsLoading(false);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       alert.error("خطایی رخ داده لطفاً دوباره تلاش کنید", {
  //         onClose: back
  //       });
  //     });

  //   setContents(cs);
  // };

  // useEffect(() => {
  //   getContetns();
  // }, []);

  return (
    <MuiThemeProvider>
      <>
        <div dir="rtl">
          <AppBar
            title="مشخصات مرسوله"
            titleStyle={{ fontFamily: vazir }}
            showMenuIconButton={false}
            style={{ backgroundColor: primaryColor }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30
            }}
          >
            <Card
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingLeft: 10,
                flexDirection: "column",
                overflow: "visible"
              }}
            >
              <CardContent style={{ zIndex: 100 }}>
                <FormControl
                  className={classes.formControl}
                  style={{ zIndex: 105 }}
                >
                  <p style={{ alignSelf: "flex-start", fontFamily: vazir }}>
                    لطفاً وزن را به کیلوگرم وارد کنید :
                  </p>
                  <TextField
                    floatingLabelText="وزن (کیلوگرم)"
                    floatingLabelStyle={{
                      fontFamily: vazir,
                      textAlign: "right"
                    }}
                    style={{
                      fontFamily: vazir,
                      width: "60%"
                    }}
                    onChange={handleChange("weigth")}
                    value={values.weigth}
                    type="number"
                    inputStyle={{ textAlign: "left", fontFamily: vazir }}
                    
                  />
                  <p
                    style={{
                      alignSelf: "flex-start",
                      fontFamily: vazir,
                      marginBottom: 25
                    }}
                  >
                    لطفاً محتویات مرسوله را انتخاب کنید :{" "}
                  </p>
                  <Select
                    className="basic-single"
                    styles={styles}
                    classNamePrefix="select"
                    value={values.content ? values.content : defaultValues[0]}
                    // isLoading={isLoading}
                    isRtl={true}
                    isSearchable={true}
                    name="content"
                    options={contents}
                    onChange={handleChange("content", true)}
                    loadingMessage={() => {
                      return "در حال بارگزاری ...";
                    }}
                  />
                </FormControl>
                <FormControl
                  className={classes.formControl}
                  style={{ zIndex: 103 }}
                >
                  <p style={{ alignSelf: "flex-start", fontFamily: vazir }}>
                    لطفاً تعداد مرسوله ها را وارد کنید :
                  </p>
                  <TextField
                    floatingLabelText="تعداد (عدد)"
                    floatingLabelStyle={{
                      fontFamily: vazir,
                      textAlign: "right"
                    }}
                    style={{
                      fontFamily: vazir,
                      width: "60%"
                    }}
                    onChange={handleChange("num")}
                    value={values.num}
                    type="number"
                    inputStyle={{ textAlign: "left", fontFamily: vazir }}
                  />
                  <p
                    style={{
                      alignSelf: "flex-start",
                      fontFamily: vazir,
                      marginBottom: 25
                    }}
                  >
                    لطفاً نحوه پرداخت را انتخاب کنید :{" "}
                  </p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={
                      values.paymentMethod
                        ? values.paymentMethod
                        : defaultValues[1]
                    }
                    styles={styles}
                    // isLoading={isLoading}
                    isRtl={true}
                    isSearchable={true}
                    name="paymentMethod"
                    options={paymentMethods}
                    onChange={handleChange("paymentMethod", true)}
                  />
                </FormControl>
              </CardContent>
              <CardContent style={{ alignSelf: "center" }}>
                <RaisedButton
                  label="ادامه"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={continues}
                  style={{ borderRadius: 35, zIndex: -200 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={secondaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
                <RaisedButton
                  label="برگشت"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={back}
                  style={{ borderRadius: 35, marginRight: 30, zIndex: -1 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={primaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    </MuiThemeProvider>
  );
};

export default CargoInfo;
