import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { AppBar, Card, RaisedButton } from "material-ui";
import { vazir, vazirMedium } from "../theme/fonts";
import { primaryColor, secondaryColor } from "../theme/colors";
import { CardContent, makeStyles, FormControl } from "@material-ui/core";
import { apiAddress } from "../apiConfig";
import axios from "axios";
import { useAlert } from "react-alert";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    alignItems: "flex-start"
  }
}));

const Confirmation = props => {
  const classes = useStyles();

  const alert = useAlert();

  const back = e => {
    e.preventDefault();
    props.prevStep();
  };

  const { values } = props;
  var sendingMethod = "";

  switch (values.sendMethod) {
    case "DTD":
      sendingMethod = "دریافت از درب منزل فرستنده و تحویل به درب منزل گیرنده";
      break;
    case "DTA":
      sendingMethod = "دریافت از درب منزل فرستنده و تحویل در فرودگاه مقصد";
      break;
    case "ATD":
      sendingMethod = "دریافت در فرودگاه مبداء و تحویل به درب منزل گیرنده";
      break;
    case "ATA":
      sendingMethod = "دریافت در فرودگاه مبداء و تحویل در فرودگاه مقصد";
      break;
    default:
      sendingMethod = "خطایی رخ داده لطفاً دوباره تلاش کنید";
      break;
  }

  const continues = e => {
    e.preventDefault();

    const FETCH_TIMEOUT = 10000;
    let didTimeout = false;

    new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        didTimeout = true;
        reject(new Error("Request time out"));
      }, FETCH_TIMEOUT);

      axios
        .post(`${apiAddress}/newOrder`, {
          sendMethod: values.sendMethod,
          origin: values.origin.label,
          destination: values.destination.label,
          sAddress: values.sAddress,
          rAddress: values.rAddress,
          sName: values.sName,
          sPhone: values.sPhone,
          rName: values.rName,
          rPhone: values.rPhone,
          weigth: values.weigth,
          content: values.content.label,
          num: values.num,
          paymentMethod: values.paymentMethod.label
        })
        .then(response => {
          clearTimeout(timeout);
          if (!didTimeout) {
            resolve(response);
          }
        })
        .catch(err => {
          console.error("Error : " + err);
          if (didTimeout) return;
          reject(err);
        });
    })
      .then(respones => {
        if (respones.data === "Done") {
          props.nextStep();
        }
      })
      .catch(err => {
        console.error(err);
        alert.error("خطایی رخ داده لطفاً دوباره تلاش کنید", {
          onClose: back
        });
      });
  };

  return (
    <MuiThemeProvider>
      <>
        <div dir="rtl">
          <AppBar
            title="مشخصات مرسوله"
            titleStyle={{ fontFamily: vazir }}
            showMenuIconButton={false}
            style={{ backgroundColor: primaryColor }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30
            }}
          >
            <Card
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingLeft: 10,
                flexDirection: "column",
                padding: 30
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column"
                }}
              >
                <FormControl className={classes.formControl}>
                  <p style={{ fontFamily: vazir }}>
                    نحوه ارسال بار : {sendingMethod}
                  </p>
                  <p style={{ fontFamily: vazir }}>
                    مبداء : {values.origin.label}
                  </p>
                  <p style={{ fontFamily: vazir }}>
                    مقصد : {values.destination.label}
                  </p>
                  <p style={{ fontFamily: vazir }}>
                    نام و نام خانوادگی فرستنده : {values.sName}
                  </p>
                  <p style={{ fontFamily: vazir }}>
                    شماره فرستنده : {values.sPhone}
                  </p>
                  {values.sendMethod === "DTA" ||
                  values.sendMethod === "DTD" ? (
                    <p style={{ fontFamily: vazir }}>
                      آدرس فرستنده : {values.sAddress}
                    </p>
                  ) : null}
                  <p style={{ fontFamily: vazir }}>
                    نام و نام ‌خانوادگی گیرنده : {values.rName}
                  </p>
                  <p style={{ fontFamily: vazir }}>
                    شماره گیرنده : {values.rPhone}
                  </p>
                  {values.sendMethod === "DTD" ||
                  values.sendMethod === "ATD" ? (
                    <p style={{ fontFamily: vazir }}>
                      آدرس گیرنده : {values.rAddress}
                    </p>
                  ) : null}
                  <p style={{ fontFamily: vazir }}>وزن : {values.weigth}</p>
                  <p style={{ fontFamily: vazir }}>تعداد : {values.num}</p>
                  <p style={{ fontFamily: vazir }}>
                    محتویات : {values.content.label}
                  </p>
                  <p style={{ fontFamily: vazir }}>
                    نحوه پرداخت : {values.paymentMethod.label}
                  </p>
                </FormControl>
              </CardContent>
              <CardContent style={{ alignSelf: "center" }}>
                <RaisedButton
                  label="تایید و ثبت سفارش"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={continues}
                  style={{ borderRadius: 35, zIndex: -200 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={secondaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
                <RaisedButton
                  label="برگشت"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={back}
                  style={{ borderRadius: 35, marginRight: 30, zIndex: -1 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={primaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    </MuiThemeProvider>
  );
};

export default Confirmation;
