import React from "react";
import SendingMethods from "./SendingMethods";
import OriginAndDestinationInfo from "./OriginAndDestinationInfo";
import SenderAndRecieverInfo from "./SenderAndRecieverInfo";
import CargoInfo from "./CargoInfo";
import Confirmation from "./Confirmation";
import Success from "./Success";

class Container extends React.Component {
  constructor() {
    super();

    //All States
    this.state = {
      //steps
      step: 1,

      //sending methods
      sendMethod: "DTD",

      //origin and destination
      origin: "",
      destination: "",
      sAddress: "",
      rAddress: "",

      //sender and recivier
      sName: "",
      sPhone: "",
      rName: "",
      rPhone: "",

      //cargo
      weigth: "",
      content: "",
      num: "",
      paymentMethod: ""
    };
  }

  //proceed next step
  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    });
  };

  //proceed prev step
  prevStep = () => {
    this.setState({
      step: this.state.step - 1
    });
  };

  //set step to 1 and clean all states
  resetSteps = () => {
    this.setState({
      step: 1,
      sendMethod: "DTD",
      origin: "",
      destination: "",
      sAddress: "",
      rAddress: "",
      sName: "",
      sPhone: "",
      rName: "",
      rPhone: "",
      weigth: "",
      content: "",
      num: "",
      paymentMethod: ""
    });
  };

  //handle fields change
  handleChange = (input, isSelect = false) => e => {
    if (!isSelect) {
      this.setState({
        [input]: e.target.value
      });
    } else {
      this.setState({
        [input]: e
      });
    }
  };

  render() {
    const {
      step,
      sendMethod,
      origin,
      destination,
      sAddress,
      rAddress,
      sName,
      sPhone,
      rName,
      rPhone,
      weigth,
      content,
      num,
      paymentMethod
    } = this.state;

    const values = {
      sendMethod,
      origin,
      destination,
      sAddress,
      rAddress,
      sName,
      sPhone,
      rName,
      rPhone,
      weigth,
      content,
      num,
      paymentMethod
    };

    switch (step) {
      case 1:
        return (
          <SendingMethods
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case 2:
        return (
          <OriginAndDestinationInfo
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case 3:
        return (
          <SenderAndRecieverInfo
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case 4:
        return (
          <CargoInfo
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case 5:
        return (
          <Confirmation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case 6:
        return <Success resetSteps={this.resetSteps}/>;

      default:
        return <h1>Error</h1>;
    }
  }
}

export default Container;
