function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = _interopDefault(require("react"));

var BaseIcon = function BaseIcon(_ref) {
  var color = _ref.color,
    _ref$pushRight = _ref.pushRight,
    pushRight = _ref$pushRight === undefined ? true : _ref$pushRight,
    children = _ref.children;
  return React.createElement(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: color,
      strokeWidth: "2",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      style: { marginRight: pushRight ? "20px" : "0", minWidth: 24 }
    },
    children
  );
};

var CloseIcon = function CloseIcon() {
  return React.createElement(
    BaseIcon,
    { color: "#FFFFFF", pushRight: false },
    React.createElement("line", { x1: "18", y1: "6", x2: "6", y2: "18" }),
    React.createElement("line", { x1: "6", y1: "6", x2: "18", y2: "18" })
  );
};

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

var alertStyle = {
  // backgroundColor: '#d63031',
  color: "white",
  padding: "10px",
  textTransform: "uppercase",
  borderRadius: "3px",
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  fontFamily: "Vazir",
  boxSizing: "border-box"
};

var buttonStyle = {
  marginRight: "20px",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  color: "#FFFFFF"
};

var AlertTemplate = function AlertTemplate(_ref) {
  var message = _ref.message,
    options = _ref.options,
    style = _ref.style,
    close = _ref.close;

  var backgroundColor;

  if (options.type === "info") {
    backgroundColor = "#f39c12";
  } else if (options.type === "error") {
    backgroundColor = "#d63031";
  } else {
    backgroundColor = "#151515";
  }

  return React.createElement(
    "div",
    { style: _extends({ backgroundColor }, alertStyle, style) },
    React.createElement("span", { style: { flex: 2 } }, message),
    React.createElement(
      "button",
      { onClick: close, style: buttonStyle },
      React.createElement(CloseIcon, null)
    )
  );
};

module.exports = AlertTemplate;
