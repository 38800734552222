import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Card, AppBar, RaisedButton } from "material-ui";
import { CardContent } from "@material-ui/core";
import { vazir, vazirMedium } from "../theme/fonts";
import { primaryColor, secondaryColor } from "../theme/colors";

const Success = props => {
  const reset = e => {
    e.preventDefault();
    props.resetSteps();
  };

  return (
    <MuiThemeProvider>
      <>
        <div dir="rtl">
          <AppBar
            title="تایید سفارش"
            titleStyle={{ fontFamily: vazir }}
            showMenuIconButton={false}
            style={{ backgroundColor: primaryColor }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30
            }}
          >
            <Card
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingLeft: 10,
                flexDirection: "column",
                padding: 30
              }}
            >
              <CardContent>
                <p style={{ fontFamily: vazir, fontSize: 18 }}>
                  سفارش شما با موفقیت ثبت شد، کارشناسان ما بزودی با شما تماس
                  میگیرند.
                </p>
              </CardContent>
              <CardContent style={{ alignSelf: "center" }}>
                <RaisedButton
                  label="ثبت سفارش جدید"
                  labelStyle={{ fontFamily: vazirMedium, color: "white" }}
                  onClick={reset}
                  style={{ borderRadius: 35, zIndex: -200 }}
                  overlayStyle={{ borderRadius: 35 }}
                  backgroundColor={secondaryColor}
                  buttonStyle={{ borderRadius: 35 }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    </MuiThemeProvider>
  );
};

export default Success;
